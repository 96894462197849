import React from 'react'	

const MapSection = () => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d909.7588913741166!2d1.7547364691023586!3d41.23593407440041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a380d2eb71b625%3A0x65c7158366c4fbe6!2sPol.%20Ind.%20Vilanoveta%2C%20Av.%20les%20Roquetes%2C%2029%2C%2008810%20Sant%20Pere%20de%20Ribes%2C%20Barcelona!5e1!3m2!1ses!2ses!4v1601569356069!5m2!1ses!2ses" 
      width="100%" 
      height="500" 
      frameBorder="0" 
      style={{border: 0}}
      allowFullScreen={true}
    >
    </iframe>
)}

export default MapSection;